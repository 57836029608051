import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../auth/store/userSlice';
import { getOfficalRates } from '../../factories/rates.factory';
import FormatAmount from '../FormatAmount/FormatAmount';

function ExpiredInvoice() {
  const dispatch = useDispatch();
  const [rates, setRates] = React.useState(null);

  useEffect(() => {
    getOfficalRates().then((fetchedRates) => {
      setRates(fetchedRates);
    });
  }, []);

  console.log({ rates });
  return (
    <div className="flex flex-col flex-1 items-center justify-center p-16">
      <div className="max-w-512 text-center">
        <FuseAnimate animation="transition.expandIn" delay={100}>
          <Typography variant="h4" color="primary" className="font-medium mb-16">
            Tu factura se encuentra vencida.
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={500}>
          <>
            <Typography variant="h6" color="textSecondary" className="mb-16 text-18">
              Si ya cancelaste, reporta el pago vía WhatsApp haciendo
              <a href="https://wa.me/584126397780"> click aquí</a>
            </Typography>
            <Box className="rounded-4 border-1 p-10 max-w-288 m-auto">
              {!!rates && (
                <Typography className="text-20 font-600 mb-8">
                  Monto: <FormatAmount amount={rates.paralelo * 7} suffix={' Bs'} />
                </Typography>
              )}

              <Typography className="text-16 font-600">Datos pago móvil</Typography>
              <Typography>Banesco (0134)</Typography>
              <Typography>Cédula: V-22.520.812</Typography>
              <Typography>Tlf: 0412-6397780</Typography>
            </Box>

            <Button onClick={() => dispatch(logoutUser())} variant="contained" color="primary" className="mt-24 normal-case">
              Cerrar sesión
            </Button>
          </>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default ExpiredInvoice;
